import Avatar01 from "../images/fpo/avatar-01.svg";
import Avatar02 from "../images/fpo/avatar-02.svg";
import Avatar03 from "../images/fpo/avatar-03.svg";
import Avatar04 from "../images/fpo/avatar-04.svg";

export const HEAT_DATA = {
    heats: {
        first: [
            {
                avatar: Avatar01,
                name: "Almond",
                score: "8",
            },
            {
                avatar: Avatar01,
                name: "AlohaMy",
                score: "4",
            },
            {
                avatar: Avatar02,
                name: "Bartonologist",
                score: "14",
            },
            {
                avatar: Avatar02,
                name: "Brittney Raines",
                score: "5",
            },
            {
                avatar: Avatar03,
                name: "Clutchbelk",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "Destroy",
                score: "13",
            },
            {
                avatar: Avatar01,
                name: "FaZe Bloo",
                score: "3",
            },
            {
                avatar: Avatar04,
                name: "Goke",
                score: "9",
            },
            {
                avatar: Avatar03,
                name: "HusKerrs",
                score: "9",
            },
            {
                avatar: Avatar04,
                name: "JaredFPS",
                score: "5",
            },
            {
                avatar: Avatar01,
                name: "Jessiecooks",
                score: "2",
            },
            {
                avatar: Avatar02,
                name: "Jukeyz",
                score: "14",
            },
            {
                avatar: Avatar02,
                name: "Newbz",
                score: "9",
            },
            {
                avatar: Avatar03,
                name: "P90princess",
                score: "10",
            },
            {
                avatar: Avatar04,
                name: "Queenshadows",
                score: "4",
            },
            {
                avatar: Avatar01,
                name: "Scummn",
                score: "3",
            },
            {
                avatar: Avatar02,
                name: "SenseiSwishem",
                score: "6",
            },
            {
                avatar: Avatar04,
                name: "ShadedStep",
                score: "19",
            },
            {
                avatar: Avatar04,
                name: "Smixie",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "Tommey",
                score: "12",
            },
            {
                avatar: Avatar04,
                name: "Wagnificent",
                score: "5",
            },
            {
                avatar: Avatar04,
                name: "zColorss",
                score: "4",
            },
        ],
        second: [
            {
                avatar: Avatar01,
                name: "Almond",
                score: "14",
            },
            {
                avatar: Avatar01,
                name: "AlohaMy",
                score: "4",
            },
            {
                avatar: Avatar02,
                name: "Bartonologist",
                score: "5",
            },
            {
                avatar: Avatar02,
                name: "Brittney Raines",
                score: "5",
            },
            {
                avatar: Avatar03,
                name: "Clutchbelk",
                score: "14",
            },
            {
                avatar: Avatar04,
                name: "Destroy",
                score: "8",
            },
            {
                avatar: Avatar01,
                name: "FaZe Bloo",
                score: "9",
            },
            {
                avatar: Avatar04,
                name: "Goke",
                score: "1",
            },
            {
                avatar: Avatar03,
                name: "HusKerrs",
                score: "1",
            },
            {
                avatar: Avatar04,
                name: "JaredFPS",
                score: "5",
            },
            {
                avatar: Avatar01,
                name: "Jessiecooks",
                score: "9",
            },
            {
                avatar: Avatar02,
                name: "Jukeyz",
                score: "12",
            },
            {
                avatar: Avatar02,
                name: "Newbz",
                score: "5",
            },
            {
                avatar: Avatar03,
                name: "P90princess",
                score: "6",
            },
            {
                avatar: Avatar04,
                name: "Queenshadows",
                score: "3",
            },
            {
                avatar: Avatar01,
                name: "Scummn",
                score: "9",
            },
            {
                avatar: Avatar02,
                name: "SenseiSwishem",
                score: "15",
            },
            {
                avatar: Avatar04,
                name: "ShadedStep",
                score: "5",
            },
            {
                avatar: Avatar04,
                name: "Smixie",
                score: "4",
            },
            {
                avatar: Avatar04,
                name: "Tommey",
                score: "15",
            },
            {
                avatar: Avatar04,
                name: "Wagnificent",
                score: "12",
            },
            {
                avatar: Avatar04,
                name: "zColorss",
                score: "11",
            },
        ],
        third: [
            {
                avatar: Avatar01,
                name: "Almond",
                score: "11",
            },
            {
                avatar: Avatar01,
                name: "AlohaMy",
                score: "7",
            },
            {
                avatar: Avatar02,
                name: "Bartonologist",
                score: "2",
            },
            {
                avatar: Avatar02,
                name: "Brittney Raines",
                score: "3",
            },
            {
                avatar: Avatar03,
                name: "Clutchbelk",
                score: "0",
            },
            {
                avatar: Avatar04,
                name: "Destroy",
                score: "5",
            },
            {
                avatar: Avatar01,
                name: "FaZe Bloo",
                score: "11",
            },
            {
                avatar: Avatar04,
                name: "Goke",
                score: "6",
            },
            {
                avatar: Avatar03,
                name: "HusKerrs",
                score: "4",
            },
            {
                avatar: Avatar04,
                name: "JaredFPS",
                score: "14",
            },
            {
                avatar: Avatar01,
                name: "Jessiecooks",
                score: "5",
            },
            {
                avatar: Avatar02,
                name: "Jukeyz",
                score: "12",
            },
            {
                avatar: Avatar02,
                name: "Newbz",
                score: "14",
            },
            {
                avatar: Avatar03,
                name: "P90princess",
                score: "5",
            },
            {
                avatar: Avatar04,
                name: "Queenshadows",
                score: "2",
            },
            {
                avatar: Avatar01,
                name: "Scummn",
                score: "9",
            },
            {
                avatar: Avatar02,
                name: "SenseiSwishem",
                score: "10",
            },
            {
                avatar: Avatar04,
                name: "ShadedStep",
                score: "2",
            },
            {
                avatar: Avatar04,
                name: "Smixie",
                score: "1",
            },
            {
                avatar: Avatar04,
                name: "Tommey",
                score: "9",
            },
            {
                avatar: Avatar04,
                name: "Wagnificent",
                score: "8",
            },
            {
                avatar: Avatar04,
                name: "zColorss",
                score: "16",
            },
        ],
        fourth: [
            {
                avatar: Avatar01,
                name: "Almond",
                score: "6",
            },
            {
                avatar: Avatar01,
                name: "AlohaMy",
                score: "1",
            },
            {
                avatar: Avatar02,
                name: "Bartonologist",
                score: "13",
            },
            {
                avatar: Avatar02,
                name: "Brittney Raines",
                score: "3",
            },
            {
                avatar: Avatar03,
                name: "Clutchbelk",
                score: "12",
            },
            {
                avatar: Avatar04,
                name: "Destroy",
                score: "8",
            },
            {
                avatar: Avatar01,
                name: "FaZe Bloo",
                score: "16",
            },
            {
                avatar: Avatar04,
                name: "Goke",
                score: "10",
            },
            {
                avatar: Avatar03,
                name: "HusKerrs",
                score: "11",
            },
            {
                avatar: Avatar04,
                name: "JaredFPS",
                score: "3",
            },
            {
                avatar: Avatar01,
                name: "Jessiecooks",
                score: "6",
            },
            {
                avatar: Avatar02,
                name: "Jukeyz",
                score: "0",
            },
            {
                avatar: Avatar02,
                name: "Newbz",
                score: "8",
            },
            {
                avatar: Avatar03,
                name: "P90princess",
                score: "1",
            },
            {
                avatar: Avatar04,
                name: "Queenshadows",
                score: "7",
            },
            {
                avatar: Avatar01,
                name: "Scummn",
                score: "8",
            },
            {
                avatar: Avatar02,
                name: "SenseiSwishem",
                score: "N/A",
            },
            {
                avatar: Avatar04,
                name: "ShadedStep",
                score: "10",
            },
            {
                avatar: Avatar04,
                name: "Smixie",
                score: "6",
            },
            {
                avatar: Avatar04,
                name: "Tommey",
                score: "14",
            },
            {
                avatar: Avatar04,
                name: "Wagnificent",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "zColorss",
                score: "16",
            },
        ],
        fifth: [
            {
                avatar: Avatar01,
                name: "Almond",
                score: "4",
            },
            {
                avatar: Avatar01,
                name: "AlohaMy",
                score: "11",
            },
            {
                avatar: Avatar02,
                name: "Bartonologist",
                score: "4",
            },
            {
                avatar: Avatar02,
                name: "Brittney Raines",
                score: "3",
            },
            {
                avatar: Avatar03,
                name: "Clutchbelk",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "Destroy",
                score: "20",
            },
            {
                avatar: Avatar01,
                name: "FaZe Bloo",
                score: "11",
            },
            {
                avatar: Avatar04,
                name: "Goke",
                score: "4",
            },
            {
                avatar: Avatar03,
                name: "HusKerrs",
                score: "11",
            },
            {
                avatar: Avatar04,
                name: "JaredFPS",
                score: "10",
            },
            {
                avatar: Avatar01,
                name: "Jessiecooks",
                score: "9",
            },
            {
                avatar: Avatar02,
                name: "Jukeyz",
                score: "12",
            },
            {
                avatar: Avatar02,
                name: "Newbz",
                score: "0",
            },
            {
                avatar: Avatar03,
                name: "P90princess",
                score: "1",
            },
            {
                avatar: Avatar04,
                name: "Queenshadows",
                score: "7",
            },
            {
                avatar: Avatar01,
                name: "Scummn",
                score: "13",
            },
            {
                avatar: Avatar02,
                name: "SenseiSwishem",
                score: "2",
            },
            {
                avatar: Avatar04,
                name: "ShadedStep",
                score: "12",
            },
            {
                avatar: Avatar04,
                name: "Smixie",
                score: "13",
            },
            {
                avatar: Avatar04,
                name: "Tommey",
                score: "6",
            },
            {
                avatar: Avatar04,
                name: "Wagnificent",
                score: "2",
            },
            {
                avatar: Avatar04,
                name: "zColorss",
                score: "19",
            },
        ],
        sixth: [
            {
                avatar: Avatar01,
                name: "Almond",
                score: "4",
            },
            {
                avatar: Avatar01,
                name: "AlohaMy",
                score: "6",
            },
            {
                avatar: Avatar02,
                name: "Bartonologist",
                score: "8",
            },
            {
                avatar: Avatar02,
                name: "Brittney Raines",
                score: "5",
            },
            {
                avatar: Avatar03,
                name: "Clutchbelk",
                score: "14",
            },
            {
                avatar: Avatar04,
                name: "Destroy",
                score: "7",
            },
            {
                avatar: Avatar01,
                name: "FaZe Bloo",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "Goke",
                score: "N/A",
            },
            {
                avatar: Avatar03,
                name: "HusKerrs",
                score: "12",
            },
            {
                avatar: Avatar04,
                name: "JaredFPS",
                score: "1",
            },
            {
                avatar: Avatar01,
                name: "Jessiecooks",
                score: "6",
            },
            {
                avatar: Avatar02,
                name: "Jukeyz",
                score: "16",
            },
            {
                avatar: Avatar02,
                name: "Newbz",
                score: "11",
            },
            {
                avatar: Avatar03,
                name: "P90princess",
                score: "3",
            },
            {
                avatar: Avatar04,
                name: "Queenshadows",
                score: "18",
            },
            {
                avatar: Avatar01,
                name: "Scummn",
                score: "3",
            },
            {
                avatar: Avatar02,
                name: "SenseiSwishem",
                score: "2",
            },
            {
                avatar: Avatar04,
                name: "ShadedStep",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "Smixie",
                score: "7",
            },
            {
                avatar: Avatar04,
                name: "Tommey",
                score: "13",
            },
            {
                avatar: Avatar04,
                name: "Wagnificent",
                score: "9",
            },
            {
                avatar: Avatar04,
                name: "zColorss",
                score: "16",
            },
        ],
    },
};

export const LEAD_DATA = {
    leaderboard: [
        {
            avatar: Avatar04,
            name: "zColorss",
            score: "82",
        },
        {
            avatar: Avatar04,
            name: "Tommey",
            score: "69",
        },
        {
            avatar: Avatar02,
            name: "Jukeyz",
            score: "66",
        },
        {
            avatar: Avatar04,
            name: "Destroy",
            score: "61",
        },
        {
            avatar: Avatar01,
            name: "FaZe Bloo",
            score: "57",
        },
        {
            avatar: Avatar04,
            name: "ShadedStep",
            score: "55",
        },
        {
            avatar: Avatar03,
            name: "Clutchbelk",
            score: "54",
        },
        {
            avatar: Avatar03,
            name: "HusKerrs",
            score: "48",
        },
        {
            avatar: Avatar01,
            name: "Almond",
            score: "47",
        },
        {
            avatar: Avatar02,
            name: "Newbz",
            score: "47",
        },
        {
            avatar: Avatar01,
            name: "Scummn",
            score: "46",
        },
        {
            avatar: Avatar02,
            name: "Bartonologist",
            score: "46",
        },
        {
            avatar: Avatar04,
            name: "Wagnificent",
            score: "43",
        },
        {
            avatar: Avatar04,
            name: "Queenshadows",
            score: "41",
        },
        {
            avatar: Avatar04,
            name: "JaredFPS",
            score: "38",
        },
        {
            avatar: Avatar04,
            name: "Smixie",
            score: "38",
        },
        {
            avatar: Avatar01,
            name: "Jessiecooks",
            score: "37",
        },
        {
            avatar: Avatar02,
            name: "SenseiSwishem",
            score: "35",
        },
        {
            avatar: Avatar01,
            name: "AlohaMy",
            score: "33",
        },
        {
            avatar: Avatar01,
            name: "Goke",
            score: "30",
        },
        {
            avatar: Avatar03,
            name: "P90princess",
            score: "26",
        },
        {
            avatar: Avatar02,
            name: "Brittney Raines",
            score: "24",
        },
    ],
};
