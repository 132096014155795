import React, { useState, useEffect } from "react";
import axios from 'axios';

import "./tournament-leaderboard.scss";

import { LEAD_DATA } from "../../mockData/heat-data";

const TournamentLeaderboard = () => {

    return (
        <div className="tournament-heat-container">
            <div className="heat-col">
                <div className="heat-title">Overall Leaderboard</div>
                <div className="heat-item">
                    <div>Team</div><div>Score</div>
                </div>
                {LEAD_DATA.leaderboard.map((entrant, index) => (
                    <div key={index} className="heat-item" id={entrant.name}>
                        <div>{entrant.name}</div><div>{entrant.score}</div>
                    </div>
                ))}
            </div>
        </div>
    )

};

export default TournamentLeaderboard;